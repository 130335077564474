import { FeatureType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import { FeatureData } from 'packages/innedit';
import React, { FC } from 'react';

import HOCGroup from '~/components/Group/HOC';
import Feature from '~/components/List/Item/Feature';
import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import ListBody from '~/containers/Espace/List/Body';
import params from '~/params/feature.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageFeaturesUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  params: { espaceId, id },
  user,
}) => {
  const model = new FeatureData({
    espaceId,
    params,
  });

  if (!id) {
    return <div>La caractéristique n&apos;existe pas ou a été supprimé</div>;
  }

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form displayHidden docId={id} model={model} type="update">
          <div className="grid md:grid-cols-3">
            <div className="md:col-span-2">
              <HOCGroup
                addOnClick={() =>
                  navigate(
                    `/espaces/${espaceId}/params/features/create?parent=${id}`,
                  )
                }
                bodyProps={{
                  className: 'p-0',
                }}
                title="Sous-caractéristiques"
              >
                <ListBody<FeatureType, FeatureData>
                  allowSorting
                  itemList={Feature}
                  model={
                    new FeatureData({
                      espaceId,
                      params,
                      orderDirection: 'desc',
                      orderField: 'datetime',
                      parentId: id,
                      wheres: {
                        parent: id,
                      },
                    })
                  }
                  search={location.search}
                  title="Sous pages"
                  user={user}
                />
              </HOCGroup>
            </div>
          </div>
        </Form>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageFeaturesUpdate);
